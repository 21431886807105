.footerBar {
  background-color: #ccc;
}

#images {
  margin: 0 -9px;
}

.government {
  padding: 20px 0;
  background-color: #dadada!important;
  width: 100%;
  height: 8em;
}

.govContainer {
  max-width: 1240px;
  padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
}

.justify-content-center {
  justify-content: center!important;
}

.text-center {
  text-align: center!important;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.img-fluid, .img-thumbnail {
  max-width: 100%;
  height: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}


@media (max-width: 991px) {
  #images {
    max-width: 90px;
  }
}

@media (max-width: 767px) {
  #images {
    max-width: 55px;
    margin: 0 -24px;
    padding: 0 5px;
  }
}

@media (max-width: 575px) {
  .government{
    height: 100%;
  }
}
