.mainLogo {
  padding-right: 16%;
}

#barItens {
  margin-right: 11%;
}

#popLogo {
  width: 119px;
}

/* Cor da barra principal */
.bg-navColor {
  background-color: #f9f9f9;
}

/* Afastamento dos itens gearais do menu */

.navMain {
  margin-left: -70px;
  margin-right: 100px;
}

.navItens {
  margin: 0 1.5rem;
}

.navbar-custom {
  min-height: 60px;
  display: flex;
}

/* Estilização dos itens do menu */

.nav-link {
  color: #3d3d3dec !important;
  font-weight: 600 !important;
  font-size: 18px;
  line-height: 23px;
}

.dropdown-toggle::after {
  font-size: 70%;
}

.nav-link:hover {
  color: #00E637 !important;
}

#nav-dropdown {
  color: #444242 !important;
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
}

#nav-dropdown:hover {
  color: #00E637 !important;
  background-color: #00098c0a !important;
  border-radius: 5%;
}

/* Ativando menu com hover */

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

/* Ajustes referentes a responsividade */

@media (max-width: 1300px) {
  .navItens {
    margin: 0 1.25rem;
  }
  .nav-link {
    font-size: 19px !important;
  }

  .img-languages {
    padding-left: 0.8rem;
  }

  .img-link {
    margin-right: 30px;
    height: 16px;
    width: auto;
  }

  .mainLogo {
    padding-right: 8%;
  }

  #barItens {
    margin-right: 7%;
  }

  #popLogo {
    width: 84px;
  }

  .navMain {
    margin-left: -20px;
  }
}

@media (max-width: 991px) {
  .img-languages {
    text-align: center;
    margin-right: 10%;
  }
  .mainLogo1 {
    display: flex !important ;
  }

  .mainLogo {
    display: none;
  }

  #popLogo {
    margin-left: 40%;
    width: 86px;
  }

  #barItens {
    margin-right: 0%;
  }

  .navbar-toggle {
    order: 10px;
    margin-left: 15px;
    margin-right: 50px;
  }

  .navMain {
    margin-left: 25%;
    width: 40%;
  }

  #nav-dropdown {
    color: #444242 !important;
    font-style: normal;
    font-weight: 450;
  }
}

@media (min-width: 1300px) {
  .img-languages {
    position: absolute;
    margin: 1.8rem 1rem;
  }

  .nav-link {
    font-size: 22px !important;
  }

  .mainLogo {
    padding-left: 2%;
  }

  .navMain {
    margin-left: -100px;
  }
}

.img-languages {
  top: 3px;
  right: 2px;
}

.img-link {
  margin-right: 3px;
}
