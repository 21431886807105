.statsGrid {
  height: 190px;
  /* rnp blue 2 */
  background:  #000A8C;
  padding: 0px 5%;
}

.statItem {
  padding-left: 96px !important;
  padding-top: 0px !important;
}

.statNumber {
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  /* identical to box height */
  /* rnp orange */

  color: #00E637;
}
.statTitle {
  width: 151px;
  height: 19px;
  font-style: normal;
  font-weight: 400;
  font-size: 15.5px;
  line-height: 19px;
  /* identical to box height */
  color: #fff;
}

@media (max-width: 991px) {
  .statNumber {
    font-size: 30px;
    line-height: 42px;
    margin-left: 35%;
  }
  .statTitle {
    font-size: 13.5px;
    height: 20px;
    line-height: 13px;
    width: 100px;
    padding-right: 20px;
    margin-left: 30%;
  }

  .statItem {
    margin: 0 -6px !important;
    padding-left: 65px !important;
    max-width: 16% !important;
  }

  .statsGrid {
    padding: 0 0%;
  }
}

@media (min-width: 1302px) {
  .statsGrid {
    padding: 0 18%;
  }
}

@media (max-width: 550px) {
  .statItem {
    padding-left: 60px !important;
    max-width: 28% !important;
  }
}
