@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Fonte padrão do site */

* {
  font-family: 'Barlow', sans-serif;
}

body {
  background-color: #fff;
}

.App {
  text-align: center;
}


/* rnp_footer_img attributes */
.rnp_img_footer {
  width: 130px;
  height: auto;
  margin-right: auto;
  margin-left: auto;
}

/* class defesa_img_footer attributes */
.defesa_img_footer {
  width: 70px;
  height: 40px;

  margin-right: 10px;
  /*  margin-left: 20px; */
}

/* class cidadania_img_footer attributes */
.cidadania_img_footer {
  width: 70px;
  height: 40px;

  margin-right: auto;
  margin-left: auto;
}

/* class saude_img_footer attributes */
.saude_img_footer {
  width: 70px;
  height: 40px;
  margin-right: auto;
  margin-left: auto;
}

.educacao_img_footer {
  width: 70px;
  height: 40px;
  margin-right: auto;
  margin-left: auto;
}

.ciencia_img_footer {
  width: 130px;
  height: 40px;
  margin-right: 15px;
  margin-left: auto;
}

.governo_img_footer {
  width: 185px;
  height: auto;
  margin-right: auto;
  margin-left: -50px !important;
}

@media (max-width: 1200px) {
  .governo_img_footer {
    margin-top: 7px;
    position: relative;
    justify-content: space-evenly;
  }
}

@media (max-width: 991px) {
  .ciencia_img_footer {
    margin-top: 5px;
  }
  .governo_img_footer {
    margin-left: -30px !important;
  }
}

@media (max-width: 645px) {
  .educacao_img_footer {
    margin-top: 5px;
  }
}

@media (max-width: 550px) {
  .saude_img_footer {
    margin-top: 10px;
  }
}


.footer-menu-background {
  background-color: rgba(241, 240, 240, 0.918);
  width: auto;
}

.linksmenufooter {
  color: gray;
}

.linksmenufooter:hover {
  color: gray;
}

.linksmenufooterc:hover {
  color: #00E637;
}

.linksmenufooterc {
  font-size: 13px;
  text-decoration: none;
  color: darkgray;
}

#c {
  font-weight: normal;
  text-decoration: none;
  font-size: 13px;
  color: darkgray;
}

#mapa_repepe {
  width: 100%;
  max-width: 870px;
  height: auto;
  max-height: 450px;
  min-height: 100px;
}
