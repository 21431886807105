body {
  background-color: #fff !important;
}

#divHeader {
  background-color: #f9f9f9;
  width: 100%;
  min-height: 97px;
}

/* Banner */

.recifeImage {
  width: 100%;
  height: 600px;
  background-color: #00098c7e;
}

.slogam {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -50%);

  font-style: normal;
  font-weight: 600;
  font-size: 67px;
  line-height: 55px;
  text-align: center;

  color: #fff;

  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.507);
}

.slogam2 {
  font-size: 26px;
  margin-top: -70px;
  font-weight: 500;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.507);
}

#container {
  width: auto;
  height: auto;
  background-color: rgb(253, 221, 182);
  max-width: 900px;
  min-width: 700px;
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

/* Quem somos */

#tituloinfo {
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  /* rnp blue */
  color: #00E637;
}

#quemSomosText {
  font-style: normal;
  font-weight: 350;
  font-size: 17.1px;
  /* rnp blacl */
  color: #444242;
  text-align: justify;
}

#quemSomos {
  padding: 40px 18% 140px;
  background-color: white;
}

.slick-arrow {
  display: none !important;
  width: 60px;
  right: 0px;
  background-color: rebeccapurple;
}

#noticiaspop {
  color: white;
  float: left;
  width: 48.7%;
  min-height: 200px;
  background-color: #0b5aa3;
  padding: 9px 0 0 2px;
  margin: 0 4px 0 3px;
  border-radius: 5px;
  border: 4px solid #ccc;
}

/* #noticiasrnp {
    color: white;
    float: right;
    width: 48.7%;
    min-height: 200px;
    background-color: #0b5aa3;
    padding: 9px 0 0 2px;
    margin: 0 2px 0 2px;
    border-radius: 5px;
    border: 5px solid #CCC;
} */

#noticiaspop h3 {
  text-align: center;
}

#noticiasrnp h3 {
  text-align: center;
}

/* .div_carousel {
  bottom: 250px;
  position: relative;
  height: auto;
  max-height: 85px;
  max-width: 2005px;
  background-color: #fdfdfd;
} */

@media (max-width: 1300px) {
  .recifeImage {
    width: 100%;
    height: 400px;
  }

  .slogam {
    font-size: 42px;
    line-height: 45px;
  }
  .slogam2 {
    font-size: 20px;
    margin-top: -8, 5%;
  }
}

@media (max-width: 991px) {
  .recifeImage {
    width: 100%;
    height: 210px;
  }

  .slogam {
    font-size: 23.2px;
    width: 95%;
    line-height: 85px;
  }
  .slogam2 {
    font-size: 13px;
    margin-top: -130px;
    line-height: 20px;
  }

  #quemSomos {
    padding: 2% 10% 15%;
  }

  #tituloinfo {
    font-size: 2.1rem;
    margin-bottom: 3%;
  }

  #quemSomosText {
    font-size: 15.09px;
  }
}

@media (max-width: 690px) {
  .slogam {
    font-size: 21.9px;
    width: 97%;
    line-height: 89px;
  }
  .slogam2 {
    font-size: 12px;
    margin-top: -125px;
    line-height: 17px;
    width: 90%;
    padding-left: 38px;
  }

  #quemSomos {
    padding: 2% 10% 29%;
  }

  #tituloinfo {
    font-size: 1.8rem;
    margin-bottom: 3%;
  }

  #quemSomosText {
    font-size: 14px;
  }
}
