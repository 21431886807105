.containerBC {
  margin: 0;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  float: right;
    
}

.linkbreadcrumb {
  color: white;
  text-decoration: none;
}

.linkbreadcrumb:hover {
  color: white;
  text-decoration: none;
}

.linkbreadcrumbatual {

  color: rgb(255, 171, 15);
}
