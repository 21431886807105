.backFooter {
  background-color: #000A8C;
  width: 100%;
}

.Footercontainer {
  width: 100%;
  padding-top: 65px;
  padding-bottom: 1%;
  background: #000A8C;
}

.ulFooter {
  list-style-type: none;
  padding-left: 0;
}

.tittleFooter {
  width: 123px;
  height: 23px;
  left: 1026px;
  top: 58px;
  font-style: normal;
  font-weight: 600;
  font-size: 19.8px;
  line-height: 23px;
  color: #fff;
  margin-bottom: 15%;
}

.itens {
  bottom: 4.55%;
  font-style: normal;
  font-weight: 400;
  font-size: 16.8px;
  line-height: 21px;
  color: #fff;
  padding: 5% 0%;
}

.itens:hover {
  color: #00E637;
}

.linksmenufooterc {
  width: 168px;
  height: 14px;
  left: 876px;
  top: 331px;

  font-style: normal;
  font-weight: 200;
  font-size: 11.8px;
  line-height: 14px;
  /* identical to box height */

  color: #ffffff;
}

.linksmenufooterc:link {
  color: white;
  font-weight: 200;
}

@media (max-width: 578px) {
  .ulFooter {
    padding-left: 35px;
  }

  .tittleFooter {
    padding-left: 35px;
  }
}
