.container {
  clear: both;
}

/* .customerContainerPg{
      margin-right: 400px;
  } */

.imgBoxC:hover {
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

#tituloSubHeadercomep {
  color: white;
  font-size: 2.1rem;
  font-weight: 500;
  background-color: #000A8C;
  padding: 30px 0px 0px 000px !important;
  margin-left: 200px !important;
}

#tituloSubHeader {
  color: white;
  font-size: 2.1rem;
  font-weight: 500;
  background-color: #000A8C;
  padding: 30px 0px 0px 340px !important;
}
.subHeader {
  background-color: #000A8C;
  height: 100px;
}

.imgBoxC {
  margin: 25px 25px;
  transition: transform 0.4s; /* Animation */
  padding: 5%;
  width: 250px;
  height: 125px;
  border: solid 2px #00e63a41;
  border-radius: 6%;
}

.img-responsiveC {
  padding: 0 5%;
  max-height: auto;
  display: block;
}

@media (max-width: 1399px) {
  #tituloSubHeader {
    padding: 30px 0px 0px 245px !important;
  }
  .subHeader {
    background-color: #000A8C;
    height: 100px;
  }
  #tituloSubHeadercomep {
    margin-left: 150px !important;
  }
}

@media (min-width: 1200px) {
  .boxClients {
    margin: 1px 25px !important;
  }
}

@media (max-width: 1200px) {
  .imgBoxC {
    width: 100%;
    padding: 5px 0px 0px 0px;
  }
  .img-responsiveC {
    padding: 5%;
  }
  #tituloSubHeadercomep {
    padding: 15px 0px 0px 000px !important;
    margin-left: 100px !important;
  }

  #ifpe {
    margin-top: 35px;
    margin-left: 40px;
  }
  #apac {
    margin-left: 35px;
    margin-top: 40px;
    max-width: 110% !important;
  }
  #facepe {
    margin-left: 45px;
    margin-top: 40px;
  }
  #ifsertao {
    margin-left: 44px;
    margin-top: 40px;
  }

  #ufpe {
    margin-left: 20px !important;
    margin-top: 2px !important;
    max-width: 80%;
  }
  #fundaj {
    margin-left: 48px;
    margin-top: 42px;
    max-width: 104% !important;
  }
  #procap {
    margin-left: 45px;
    margin-top: 35px;
  }
  #secti {
    margin-left: 50px;
    margin-top: 35px;
    max-width: 105% !important;
  }
  #ses {
    margin-left: 45px;
    margin-top: 40px;
  }
  #ipa {
    margin-left: 35px;
    margin-top: 43px;
  }
  #ufrpe {
    margin-top: 38px;
  }
  #upe {
    margin-left: 45px;
    margin-top: 40px;
  }
  #univasf {
    margin-left: 45px;
    margin-top: 35px;
  }
  #chesf {
    margin-left: 45px;
    margin-top: 35px;
  }
  #hcp {
    margin-top: 35px;
    max-width: 100% !important;
  }
  #cmr {
    margin-top: 35px;
  }
  #cpor {
    margin-top: 35px;
  }
  #ipem {
    margin-left: 45px;
    margin-top: 35px;
  }
  #imip {
    margin-left: 45px;
    margin-top: 35px;
    max-width: 90% !important;
  }
  #itep {
    margin-left: 45px;
    margin-top: 40px;
    max-width: 125% !important;
  }
  #ons {
    margin-left: 45px;
    margin-top: 40px;
    max-width: 125% !important;
  }
}
@media (max-width: 991px) {
  .imgBoxC {
    width: 110%;
    padding: 5px 0px 0px 0px;
    margin-top: 10%;
    height: 100px;
  }
  #tituloSubHeadercomep {
    padding: 15px 0px 0px 000px !important;
    margin-left: 40px !important;
  }

  #ifpe {
    margin-left: 40px;
  }
  #apac {
    margin-left: 38px;
    margin-top: 40px;
    max-width: 111% !important;
  }
  #facepe {
    margin-left: 45px;
    margin-top: 40px;
  }
  #ifsertao {
    margin-left: 44px;
    margin-top: 40px;
  }

  #ufpe {
    margin-left: 13px !important;
    margin-top: 1px !important;
  }
  #fundaj {
    margin-left: 48px;
    margin-top: 42px;
    max-width: 104% !important;
  }
  #procap {
    margin-left: 45px;
    margin-top: 35px;
  }
  #secti {
    margin-left: 50px;
    margin-top: 35px;
    max-width: 105% !important;
  }
  #ses {
    margin-left: 45px;
    margin-top: 45px;
  }
  #ipa {
    margin-left: 35px;
    margin-top: 43px;
  }
  #ufrpe {
    margin-top: 45px;
  }
  #upe {
    margin-left: 45px;
    margin-top: 40px;
  }
  #univasf {
    margin-left: 45px;
    margin-top: 40px;
  }
  #chesf {
    margin-left: 45px;
    margin-top: 35px;
  }
  #hcp {
    margin-top: 35px;
    max-width: 100% !important;
  }
  #cmr {
    margin-top: 42px;
  }
  #cpor {
    margin-top: 42px;
  }
  #ipem {
    margin-left: 45px;
    margin-top: 40px;
  }
  #imip {
    margin-left: 45px;
    margin-top: 35px;
    max-width: 90% !important;
  }
  #itep {
    margin-left: 45px;
    margin-top: 40px;
    max-width: 125% !important;
  }
  #ons {
    margin-left: 45px;
    margin-top: 40px;
    max-width: 125% !important;
  }
}

@media (min-width: 767px) and (max-width: 1199px) {


  #tituloSubHeader {
    padding: 24px 0px 0px 30px !important;
    font-size: 1.7rem;
  }
  .subHeader {
    background-color: #000A8C;
    height: 80px;
  }
  #tituloSubHeadercomep {
    font-size: 1.7rem;
  }

}
@media (max-width: 767px) {


  #tituloSubHeader {
    padding: 24px 0px 0px 30px !important;
    font-size: 1.7rem;
  }
  #tituloSubHeadercomep {
    padding: 24px 0px 0px 000px !important;
    margin-left: 30px !important;
    font-size: 1.7rem;
  }
  .subHeader {
    background-color: #000A8C;
    height: 80px;
  }

  .imgBoxC {
    width: 85%;
    padding: 5px 0px 0px 0px;
    margin-top: 10%;
    height: 115px;
  }

  #ifpe {
    margin-top: 45px;
    margin-left: 40px;
  }
  #apac {
    margin-left: 35px;
    margin-top: 43px;
    max-width: 110% !important;
  }
  #facepe {
    margin-left: 45px;
    margin-top: 43px;
  }
  #ifsertao {
    margin-left: 50px;
    margin-top: 49px;
    width: 99%;
  }

  #ufpe {
    margin-left: 20px !important;
    margin-top: 14px !important;
    max-width: 80%;
  }
  #fundaj {
    margin-left: 49px;
    margin-top: 50px;
  }
  #procap {
    margin-left: 50px;
    margin-top: 45px;
    width: 98%;
  }
  #secti {
    margin-left: 55px;
    margin-top: 47px;
    width: 98%;
  }
  #ses {
    margin-left: 48px;
    margin-top: 51px;
    width: 78%;
  }
  #ipa {
    margin-left: 35px;
    margin-top: 45px;
  }
  #ufrpe {
    margin-top: 45px;
  }
  #upe {
    margin-left: 45px;
    margin-top: 45px;
  }
  #univasf {
    margin-left: 45px;
    margin-top: 49px;
  }
  #chesf {
    margin-left: 45px;
    margin-top: 40px;
  }
  #hcp {
    margin-top: 44px;
    max-width: 100% !important;
  }
  #cmr {
    margin-top: 48px;
  }
  #cpor {
    margin-top: 47px;
  }
  #ipem {
    margin-left: 45px;
    margin-top: 45px;
  }
  #imip {
    margin-left: 47px;
    margin-top: 45px;
  }
  #itep {
    margin-left: 45px;
    margin-top: 45px;
  }
  #ons {
    margin-left: 57px;
    margin-top: 52px;
    width: 220px;
  }
}
