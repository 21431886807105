.wrapper {
  padding: 3% 0%;
  background-color: rgb(255, 255, 255);
  width: 100%;
  margin: 0 auto;
}

.sliderBox:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: radial-gradient(circle, rgba(153, 119, 119, 0) 80%, rgba(255, 255, 255, 1) 95%);
}

.sliderBox {
  padding: 20px 0;
}

.tittle {
  text-align: center;
  font-style: normal;
  font-weight: 700;
  padding-bottom: 1.5%;
  font-size: 2.7rem;
  color: #00E637;
}

.container img {
  margin: 20%;
}

.imgBox {
  padding: 5%;
  width: 250px !important;
  height: 125px !important;
  border: solid 2px #00e63a2d;
  border-radius: 6%;
}

.img-responsive {
  padding: 0 5%;
  max-height: auto;
  display: block;
}

@media (max-width: 599px) {
  .sliderBox:after {
    background: radial-gradient(circle, rgba(153, 119, 119, 0) 70%, rgba(255, 255, 255, 1) 85%);
  }
  .imgBox {
    width: 200px !important;
    height: 100px !important;
  }
  .tittle {
    font-size: 2rem;
    padding-top: 15px;
  }
}
