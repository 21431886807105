body {
  background-color: #fff !important;
}

iframe {
  border-radius: 5px;
  border: solid #00E6377e 1px;
}

.MuiTypography-body1 {
  font-weight: 500 !important;
}

.MuiBreadcrumbs-li {
  font-weight: 400 !important;
  font-size: 1.2rem;
}

.linkbreadcrumbatual {
  font-weight: 400 !important;
  font-size: 1.2rem !important;
}

/* #crumbsMenu{
  height: 100px;
} */

#tituloInfra {
  color: white;
  font-size: 2.1rem;
  font-weight: 500;
  padding-left: 200px;
  background-color: #000A8C;
  /* padding-top: 30px; */
}

#sublink {
  background-color: #000A8C;
}

.containerBc {
  padding: 40px 0 20px 40px !important;
  font-size: 3rem !important;
}

#netRef {
  margin-top: 7%;
}

#netRef p {
  font-size: 0.9rem;
  font-weight: 400;
  text-align: start !important;
}

.infraP {
  color: rgb(0, 0, 0) !important;
  padding: 50px 50px 30px;
  box-shadow: 0 0 1.2em #2b2b2bc5;
  border-radius: 8px;
  background-color: #f6f7fa;
  margin: 45px 10px 0px;
  font-style: normal;
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 1.5;
}

#texto-recursos {
  width: 2000px;
  padding: 10px;
}

#spotName {
  color: #00E637d2;
  font-weight: 500;
}

.infraImg {
  background: #f6f7fa;
  border: solid 2px #00E637d2;
  border-radius: 8px;
}

.maps {
  height: 460px;
  width: 105%;
  margin-left: -2.4%;
  display: inline-block;
  overflow: hidden;
  border-radius: 15px;
  margin-top: 20px;
  border: 2px #00E637 solid;
}

#mapaIcone {
  margin: 40px 80px 20px 45px !important;
  max-width: 90%;
}
#mapaVasf {
  margin: 50px 80px 20px 55px !important;
  max-width: 85%;
}

#mapaRepepe {
  margin: 50px 80px 20px 38px !important;
  max-width: 90%;
}

#mapaVeredas {
  margin: 5px 80px 10px 120px !important;
  max-width: 73%;
}

#mapaIxbr {
  margin: 50px 80px 20px 50px !important;
  max-width: 90%;
}

#listsN {
  list-style: none; /* Remove default bullets */
}

#listsN #listsL::before {
  content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #00E637d2; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 0.5em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
  font-size: 1.6rem;
}

@media (max-width: 767px) {
  .MuiBreadcrumbs-li {
    font-size: 1.1rem !important;
  }
  .MuiTypography-body1 {
    font-size: 1.1rem !important;
  }

  .makeStyles-breadcrumb-4 {
    padding: 40px 10px 40px 0 !important;
  }

  #titulo {
    padding: 40px 0 40px 10px !important;
  }

  .maps {
    height: 250px;
  }
  .infraP {
    padding: 30px;
    margin: 25px -15px 20px;
    font-size: 1rem;
  }

  #mapaIcone {
    margin: 30px 40px 40px 15px !important;
    max-width: 90%;
  }
  #mapaVasf {
    margin: 30px 0px 40px 15px !important;
    max-width: 90%;
  }

  #mapaRepepe {
    margin: 30px 0px 40px 10px !important;
    max-width: 95%;
  }

  #mapaVeredas {
    margin: 0px 0px 0px 10px !important;
    max-width: 95%;
  }

  #mapaIxbr {
    margin: 30px 0px 40px 30px !important;
    max-width: 85%;
  }
}

@media (max-width: 500px) {
  .MuiBreadcrumbs-li {
    font-size: 0.8rem !important;
  }
  .MuiTypography-body1 {
    font-size: 0.8rem !important;
  }

  .makeStyles-breadcrumb-4 {
    padding: 40px 15px 40px 0 !important;
  }

  #titulo {
    padding: 35px 0 40px 15px !important;
    font-size: 1.5rem !important;
    max-width: 160px !important;
  }
}
