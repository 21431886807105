.MuiTypography-body1 {
  font-weight: 500 !important;
}

#tituloContact {
  color: white;
  font-size: 2.2rem;
  font-weight: 500;
  padding-left: 200px;
  background-color: #000a8c;
}
.contactContainer {
  margin: auto;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  /* rnp blacl */
  color: #444242;
  padding: 70px 0 220px;
  box-shadow: 0 0 1em #4d4d4d52;
}

.contactCard {
  height: 450px;
}

.contactItens {
  color: white;
  padding: 50px;
  margin: 10px 0px 0px 10px;
  box-shadow: 0 0 1.7em #4d4d4da2;
  border-radius: 8px 0px 0px 8px;
  background-color: #000a8c;
  height: 130%;
  width: 140%;
}

.contactItensMap {
  width: 100%;
  height: 130%;
  margin: 10px 10px 10px 0px;
  box-shadow: 0 0 1em #4d4d4d49;
  border-radius: 0px 8px 8px 0px;
}

.contactItensContact {
  width: 90%;
  padding: 30px;
  margin: 10px 10px 20px;
  box-shadow: 0 0 1em #4d4d4d52;
  border-radius: 2%;
}

.contactRow {
  margin-left: 0 !important;
  margin-right: -6.5% !important;
}

#peix {
  margin-left: 0px !important;
  margin-right: -0.5% !important;
}

#mapPin {
  color: #00e637d2;
}

.contactItens p {
  margin: 0px 5px 15px;
}

#itepid {
  margin-bottom: 0px;
}

.linkContact {
  color: #00e637d2;
  font-weight: 400;
}

.linkRef {
  font-weight: 400;
}

.cardTitle {
  font-weight: 500;
  padding-top: 18px;
}

#cardTitleMain {
  font-weight: 500;
}

.cardIcons {
  margin-right: 2px;
}

.linkContact:hover {
  color: #ffffff;
}

.linkRef:hover {
  color: #000a8c;
}

.linkContact:link {
  text-decoration: none;
}


.contactAddress {
  font-weight: 350;
  font-size: 13.5px;
}

.cardItem {
  font-weight: 350;
  font-size: 17px;
}

@media (min-width: 1200px) and (max-width: 1980px) {
  #cardCenter {
    margin-left: 180px;
  }
}

@media (min-width: 1981px) {
  #cardCenter {
    margin-left: 240px;
  }
  .contactItens {
    width: 30% !important;
  }
  .contactItensMap {
    width: 50% !important;
  }
}

@media (max-width: 1199px) {
  .contactContainer {
    padding-bottom: 700px;
  }
  .contactItensMap {
    margin-left: 10px;
    border-radius: 0px 0px 8px 8px;
  }

  .contactItens {
    border-radius: 8px 8px 0px 0px;
  }

  .contactAddress {
    font-size: 12.5px;
  }
  .contactCard {
    height: 55%;
  }
}

@media (max-width: 600px) {
  .cardItem {
    font-size: 14px;
  }
  .cardTitle {
    font-size: 15.3px;
  }
  #cardTitleMain {
    font-size: 15.3px;
  }
  .contactItens {
    padding-top: 35px;
  }
}

/* #subButton {
  background-color: #243e90;
  max-width: 90%;
}

.assunto {
  max-width: 10%;
}

.formContainer {
  padding-left: 6%;
  padding-bottom: 20px;
} */
