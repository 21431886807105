body {
  background-color: #fff !important;
}

.MuiTypography-body1 {
  font-weight: 500 !important;
}

.MuiBreadcrumbs-li {
  font-weight: 400 !important;
  font-size: 1.2rem;
}

.linkbreadcrumbatual {
  font-weight: 400 !important;
  font-size: 1.2rem !important;
}

#tituloEquipe {
  color: white;
  font-size: 2.2rem;
  font-weight: 500;
  padding-left: 200px;
  background-color: #000A8C;
}

.containerBc {
  padding: 40px 0 20px 40px !important;
  font-size: 3rem !important;
}

.containerTeam {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 100px;
}

.container .card {
  position: relative;
  width: 297px;
  height: 374.4px;
  padding: 0 25px;
  margin: 30px 20px;
  background: #f6f7fa;
  box-shadow: 0.6em 0.6em 1.2em #dbdbdb, -0.5em -0.5em 1em #fff;
  border-radius: 10px;
}

.container .card .content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 10px;
}
.container .card .content .imgBx {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  position: relative;
  margin-bottom: 55px;
  overflow: hidden;
  top: 40px;
  left: 0;
  object-fit: cover;
}

.teamPic {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: 2px solid #00E637;
  margin: 0 !important;
}

.container .card .content .contentBx h4 {
  color: #000000;
  font-size: 1.7rem;
  font-weight: 600;
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: 5px;
}
.container .card .content .contentBx h5 {
  color: #444242;
  font-size: 1.3rem;
  margin-bottom: 5px;
  font-weight: 400;
  text-align: center;
}

.container .card .content .sci {
  margin-top: 5px;
}

.container .card .content .sci a {
  text-decoration: none;
  color: #00E637;
  font-size: 25px;
  margin: 10px;
  transition: color 0.4s;
}
.container .card .content .sci a:hover {
  color: #000A8C;
}

@media (max-width: 767px) {
  .MuiBreadcrumbs-li {
    font-size: 1.1rem !important;
  }
  .MuiTypography-body1 {
    font-size: 1.1rem !important;
  }

  #subTitleMenu {
    padding: 40px 10px 40px 0 !important;
  }

  #titulo {
    padding: 40px 0 40px 10px !important;
  }
}

@media (max-width: 500px) {
  .MuiBreadcrumbs-li {
    font-size: 0.8rem !important;
  }
  .MuiTypography-body1 {
    font-size: 0.8rem !important;
  }

  #subTitleMenu {
    padding: 40px 15px 40px 0 !important;
  }

  #titulo {
    padding: 35px 0 40px 15px !important;
    font-size: 1.5rem !important;
    max-width: 160px !important;
  }
}
